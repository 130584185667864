<template>
    <widget-card :bodyStyle="bodyStyle" ref="container">
        <canvas ref="chart" class="chart"></canvas>
    </widget-card>
</template>
<script>
import WidgetCard from './WidgetCard';
import VueCharts from 'vue-chartjs';

export default {
    data() {
        return {
            myChart: null,
            bodyStyle: {
                display: 'flex',
                alignItems: 'center'
            }
        };
    },
    mounted() {
        this.$refs.chart.width = this.$refs.container.$el.offsetWidth - 20;
        this.$refs.chart.height = this.$refs.container.$el.offsetHeight - 20;
        var gradient = this.$refs.chart.getContext('2d').createLinearGradient(0, 0, 0, 200);

        gradient.addColorStop(0, 'rgba(32,160,255,0.7)');
        gradient.addColorStop(1, 'rgba(32,160,255,0)');
        this.myChart = new Chart(this.$refs.chart.getContext('2d'), {
            type: 'line',
            data: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [
                    {
                        label: 'Data One',
                        backgroundColor: gradient,
                        borderColor: '#20a0ff',
                        borderWidth: '1px',
                        data: [40, 39, 10, 40, 39, 80, 40]
                    }
                ]
            },
            options: { responsive: true, maintainAspectRatio: false }
        });
    },
    components: {
        WidgetCard
    }
};
</script>
<style lang="less" scoped></style>
