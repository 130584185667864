<template>
    <widget-card :bodyStyle="bodyStyle">
        <i class="fa-fw fas fa-user fa-3x" style="color: #40c9c6"></i>
        <div class="info">
            <div class="text">User</div>
            <div class="num">4,258</div>
        </div>
    </widget-card>
</template>
<script>
import WidgetCard from './WidgetCard';

export default {
    data() {
        return {
            bodyStyle: {
                display: 'flex',
                alignItems: 'center'
            }
        };
    },
    components: {
        WidgetCard
    }
};
</script>
<style lang="less" scoped>
.info {
    flex-grow: 1;
    text-align: right;
    .text {
        color: #999;
        font-size: 16px;
        margin-bottom: 12px;
    }
    .num {
        font-size: 20px;
        color: #333;
    }
}
</style>
