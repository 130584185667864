<template>
    <widget-card :bodyStyle="bodyStyle" ref="container">
        <canvas ref="chart" class="chart"></canvas>
    </widget-card>
</template>
<script>
import WidgetCard from './WidgetCard';
import VueCharts from 'vue-chartjs';

export default {
    data() {
        return {
            myChart: null,
            bodyStyle: {
                display: 'flex',
                alignItems: 'center'
            }
        };
    },
    mounted() {
        this.$refs.chart.width = this.$refs.container.$el.offsetWidth - 20;
        this.$refs.chart.height = this.$refs.container.$el.offsetHeight - 20;
        this.myChart = new Chart(this.$refs.chart.getContext('2d'), {
            type: 'bar',
            data: {
                labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
                datasets: [
                    {
                        label: '# of Votes',
                        data: [12, 19, 3, 5, 2, 3],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }
                ]
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: false
                            }
                        }
                    ]
                }
            }
        });
    },
    components: {
        WidgetCard
    }
};
</script>
<style lang="less" scoped></style>
