<template>
    <widget-card :bodyStyle="bodyStyle" ref="container">
        <canvas ref="chart" class="chart"></canvas>
    </widget-card>
</template>
<script>
import WidgetCard from './WidgetCard';
import VueCharts from 'vue-chartjs';

export default {
    data() {
        return {
            myChart: null,
            bodyStyle: {
                display: 'flex',
                alignItems: 'center'
            }
        };
    },
    mounted() {
        this.$refs.chart.width = this.$refs.container.$el.offsetWidth - 20;
        this.$refs.chart.height = this.$refs.container.$el.offsetHeight - 20;
        this.myChart = new Chart(this.$refs.chart.getContext('2d'), {
            type: 'pie',
            data: {
                labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
                datasets: [
                    {
                        backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
                        data: [40, 20, 80, 10]
                    }
                ]
            },
            options: { responsive: true, maintainAspectRatio: false }
        });
    },
    components: {
        WidgetCard
    }
};
</script>
<style lang="less" scoped></style>
