<template>
    <el-card shadow="hover" :body-style="[mBodyStyle, bodyStyle]" class="dashboard-widget-card">
        <template v-slot:header>
            <slot name="header"></slot>
        </template>
        <template :class="className">
            <slot></slot>
        </template>
    </el-card>
</template>
<script>
export default {
    props: ['bodyStyle'],
    data() {
        return {
            mBodyStyle: {
                flexGrow: 1
            }
        };
    }
};
</script>
<style lang="less" scoped>
.dashboard-widget-card {
    display: flex;
    flex-direction: column;
}
</style>
