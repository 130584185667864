<template>
    <div class="dashboard">
        <grid-layout
            style="margin: 0 -10px"
            :layout="layout"
            :col-num="12"
            :row-height="30"
            :is-draggable="editable"
            :is-resizable="editable"
            :is-mirrored="false"
            :vertical-compact="true"
            :margin="[10, 10]"
            :use-css-transforms="true"
        >
            <grid-item
                v-for="item in layout"
                class="widget-wrapper"
                :x="item.x"
                :y="item.y"
                :w="item.w"
                :h="item.h"
                :i="item.i"
                :key="item.i"
            >
                <component :is="item.name"></component>
            </grid-item>
        </grid-layout>
        <el-button v-if="editable" @click="save">保存</el-button>
        <el-button v-else @click="editable = true">编辑</el-button>
    </div>
</template>

<script>
import { GridLayout, GridItem } from 'vue-grid-layout';
import UserWidget from '../widgets/UserWidget';
import LineChartWidget from '../widgets/LineChartWidget';
import BarChartWidget from '../widgets/BarChartWidget';
import PieChartWidget from '../widgets/PieChartWidget';

export default {
    created() {},
    data() {
        return {
            layout: [
                { x: 0, y: 0, w: 6, h: 4, i: '0', name: 'UserWidget' },
                { x: 6, y: 0, w: 6, h: 4, i: '1', name: 'UserWidget' },
                { x: 0, y: 4, w: 6, h: 6, i: '2', name: 'BarChartWidget' },
                { x: 0, y: 10, w: 6, h: 6, i: '3', name: 'LineChartWidget' },
                { x: 6, y: 4, w: 6, h: 12, i: '4', name: 'PieChartWidget' }
            ],
            editable: false
        };
    },
    methods: {
        save() {
            console.log(JSON.stringify(this.layout));
            this.editable = false;
        }
    },
    components: {
        GridLayout,
        GridItem,
        UserWidget,
        LineChartWidget,
        BarChartWidget,
        PieChartWidget
    }
};
</script>

<style lang="less" scoped>
.dashboard {
    padding: 10px 20px;
}
/deep/ .widget-wrapper {
    display: flex;
    flex-direction: column;
    .el-card {
        flex-grow: 1;
    }
}
</style>
